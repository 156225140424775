import React, { memo, useState, useContext, useMemo, useCallback, useEffect } from 'react'
import TaDialog from '../../components/dialog/TaDialog'
import InfoLicenceAcc from './components/InfoLicenceAcc'
import { useForm } from 'react-hook-form'
import { SnackbarContext } from 'components/snackbar/SnackbarContext'
import TaTypography from 'components/typography/TaTypography'
import { Grid } from '@material-ui/core'
import { isOK } from 'helper/RestStatus'
import { PostLicenseAcc } from 'services/API/license'
import TaListTextField from 'components/textfield/TaListTextField'
import { initialInfoAliComerModel, initialIPaymentMethod, initialValuesTc } from 'views/register/RegisterModel'
import { paymentMethodsList } from 'views/register/RegisterConstants'
import Transfer from 'views/register/Transfer'
import Totalcoin from 'views/register/Totalcoin'
import Zelle from 'views/register/Zelle'
import { postLogin } from 'services/API/login'
import { getZelleCode, postZelle } from 'services/API/zelle'
import { postRegisterAcc } from 'services/API/register'
import uuid from 'react-uuid'
import ModalConfirmation from 'views/register/ModalConfirmation'
import TaButton from 'components/button/TaButton'
import { fnCalculateIvaForRetAgent } from "../register/RegisterFunctions"
import NumberTextField from 'components/textfield/NumberTextField'

const RegisterAccounting = memo(({ onClose }) => {

  const [isInfoLic, setIsInfoLic] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isDisabledField, setIsDisabledField] = useState(true)
  const [appCost, setAppCost] = useState(70)
  const [banksAdded, setBanksAdded] = useState([])
  const [maxPayAmount, setMaxPayAmount] = useState(0)
  const [zelleCode, setZelleCode] = useState("")
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false)
  const [businessPartnerInfo, setBusinessPartnerInfo] = useState(initialInfoAliComerModel)
  const { sbDispatch } = useContext(SnackbarContext)
  const { register: registerLicAcc, control: controlLicAcc, handleSubmit: handleSubmitLicAcc, setValue: setValueLicAcc, watch: watchLicAcc, getValues: getValuesLicAcc } = useForm()
  const isPersBill = watchLicAcc("isPersBill")
  const watchIsAgentRet = watchLicAcc('isAgentRet')
  const resetLicAcc = (isLicense) => {
    if (isLicense == false) {
      setValueLicAcc('license', '')
    }
    setValueLicAcc('fiscalName', '')
    setValueLicAcc('rif', '')
    setValueLicAcc('isPersBill', false)
    setValueLicAcc('isAgentRet', false)
    setValueLicAcc('companyQty', 1)
  }
  const { register: registerPay, setValue: setValuePay, watch: watchPay, getValues: getValuesPay } = useForm({ defaultValues: initialIPaymentMethod })

  const { register: registerQty, setValue: setValueQty, watch: watchQty, getValues: getValuesQty } = useForm({ defaultValues: { companyQty: 1 } })
  const companyQty = watchQty('companyQty')

  const { handleSubmit: handleSubmitTc, register: registerTc, errors: errorsTc, setValue: setValueTc, watch: watchTc, getValues: getValuesTc } =
    useForm({ defaultValues: initialValuesTc });
  const resetTc = () => {
    setValueTc('email', '')
    setValueTc('pass', '')
    setValueTc('balance', '')
    setValueTc('price', '')
    setValueTc('remaining', '')
    setValueTc('codAliComer', '')
  }

  const fnWipeRegisterForm = (isLicense) => {
    resetLicAcc(isLicense)
    resetTc();
    setValuePay('paymentMethod', '')
    setBanksAdded([])
  }

  const fnGetInfoLicence = async (data) => {
    fnWipeRegisterForm(true)
    setIsLoading(true)
    try {
      const response = await PostLicenseAcc(data.license)
      if (isOK(response.status)) {
        const { idCompany, businessName, licType, name, phone, email } = response.data.Data
        setIsDisabledField(false)
        setValueLicAcc('fiscalName', businessName)
        setValueLicAcc('rif', idCompany)
        setValueLicAcc('companyQty', 1)
        setValueLicAcc('licenseType', licType)
        if (response.data.Data?.name != null) {
          setBusinessPartnerInfo({ name, phone, email })
          fnModalConfirm();
        }
        setIsDisabledField(true)
        setIsInfoLic(true)
      } else {
        sbDispatch.warning(response.data?.Message ?? "Error consultando licencia, reintente nuevamente")
        resetLicAcc()
      }
    } catch (error) {
      console.error('fnSubmitRegister => ', error)
      sbDispatch.error('Error no controlado consultando licencia, Intente de nuevo')
    }
    setIsLoading(false)
  }

  const fnOnSubmitTc = async (data) => {
    setIsLoading(true);
    try {
      const response = await postLogin(data.email, data.pass, companyQty)
      if (response.status === 200) {
        const { codUser, dif, saldoTC, valorApp } = response.data[0]
        setValueTc("codAliComer", codUser)
        setValueTc("balance", saldoTC)
        setValueTc("price", valorApp)
        setValueTc("remaining", dif)
      } else {
        resetTc();
        sbDispatch.warning(response.data?.Message ?? "Error iniciando sesion contra totalcoin, reintente nuevamente")
      }
    } catch (error) {
      sbDispatch.warning("Error no controlado iniciando sesion contra totalcoin, reintente nuevamente")
      console.error('error on fnOnSubmitTc license => ', error)
    }
    setIsLoading(false);
  }

  const fnGetZelleCode = async () => {
    setIsLoading(true);
    try {
      const response = await getZelleCode()
      if (response.status === 200) {
        const { cod } = response.data
        setZelleCode(cod)
      } else {
        setZelleCode("")
        sbDispatch.warning(response.data?.message ?? "Error obteniendo informacion de Zelle, reintente nuevamente")
      }
    } catch (error) {
      sbDispatch.warning("Error no controlado obteniendo informacion de Zelle, reintente nuevamente")
      console.error('error on fnOnSubmitTc license => ', error)
    }
    setIsLoading(false);
  }

  const fnOnSubmitZelle = async () => {
    setIsLoading(true)
    try {
      const response = await postZelle(zelleCode, appCost)
      if (isOK(response.status)) {
        if (response.data.validated === true) {
          fnPostRegister()
        }else{
          fnPostRegister(zelleCode)
          //sbDispatch.warning('No se pudo verificar el pago, contacte a administración')
        }
      }
    } catch (error) {
      console.error('fnOnSubmitZelle =>', error)
      sbDispatch.warning('Error no controlado verificar el pago, intente nuevamente')
    }
    setIsLoading(false)
  }

  const fnPostRegister = async (zelleCode = '') => {
    setIsLoading(true)
    try {
      const response = await postRegisterAcc(getValuesLicAcc(), getValuesTc(), getValuesPay(), banksAdded, appCost, companyQty, zelleCode)
      if (isOK(response.status)) {
        fnWipeRegisterForm()
        sbDispatch.success('Se ha registrado con exito')
      } else {
        sbDispatch.api(response.status, response.data)
      }
    } catch (error) {
      console.error('fnPostRegister =>', error)
      sbDispatch.warning('Error no controlado realizando el registro, reintente nuevamente')
    }
    setIsLoading(false)
  }

  const fnSubmitRegister = async () => {
    try {
      switch (currentPaymentMethod) {
        case "1":
          if (banksAdded.reduce((val, x) => (val + x.amount), 0) !== maxPayAmount) {
            return sbDispatch.warning("Debe cancelar el monto completo")
          }
          break
        case "2":
          if (getValuesTc("codAliComer") === "") {
            return sbDispatch.warning("Debe iniciar sesión para registrar la compra")
          }
          break;
        default:
      }
      return fnPostRegister()
    } catch (error) {
      console.error('fnSubmitRegister => ', error)
      sbDispatch.warning("Error no controlado realizando el registro de la compra, intente nuevamente")
    }
  }

  const fnAddBank = useCallback((data) => setBanksAdded(preVal => [...preVal, { ...data, id: uuid() }]), [])

  const fnDelBank = useCallback((id) => setBanksAdded(preVal => preVal.filter(x => x.id !== id)), [])

  const fnModalConfirm = () => setIsOpenModalConfirm(!isOpenModalConfirm)

  const fnAcceptBussinessPartner = (id) => {
    switch (id) {
      case 'btnContact':
        resetLicAcc(false)
        break;
      default:
        break
    }
    setBusinessPartnerInfo(initialInfoAliComerModel)
    fnModalConfirm()
  }

  const currentPaymentMethod = useMemo(() => {
    switch (watchPay("paymentMethod")) {
      case "1":
      case "3":
      case "4":
        resetTc();
        break
      default:
        break
    }
    return watchPay("paymentMethod");
  }, [watchPay("paymentMethod")])

  useEffect(() => {
    if (companyQty < 1) {
      setValueQty('companyQty', 1)
    } else {
      setAppCost(fnCalculateIvaForRetAgent(watchIsAgentRet) * companyQty)
    }
    resetTc()
  }, [companyQty])
  useEffect(() => setValueLicAcc('isAgentRet', false), [isPersBill])
  useEffect(() => currentPaymentMethod !== "" && setAppCost(fnCalculateIvaForRetAgent(watchIsAgentRet) * companyQty), [watchIsAgentRet, currentPaymentMethod])

  return (
    <TaDialog
      id={'ModalRegisterAccounting'}
      isOpen={true}
      maxWidth={'lg'}
      onClose={onClose}
      title={'Comprar Licencia'}
      content={
        <>
          <InfoLicenceAcc
            registerLicAcc={registerLicAcc}
            isDisabledField={isDisabledField}
            isInfoLic={isInfoLic}
            controlLicAcc={controlLicAcc}
            handleSubmitLicAcc={handleSubmitLicAcc}
            fnSubmitRegister={fnGetInfoLicence}
            isPersBill={isPersBill} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <form key={'paymentAcc'} onKeyPress={(e) => (e.key === "Enter" && e.preventDefault())}>
                <Grid container spacing={2} alignItems="center" alignContent="center">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TaTypography text="Forma de pago" fontSize="14pt" color="var(--main-text-color0)" />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                    <TaListTextField
                      name={'paymentMethod'}
                      label={'Método de pago'}
                      register={registerPay}
                      required={true}
                      setValue={setValuePay}
                      options={paymentMethodsList}
                      fullWidth={true}
                      disabled={!isInfoLic} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={8} xl={8} hidden={currentPaymentMethod === '2' || currentPaymentMethod === ''}>
                    <TaTypography text={`Monto a pagar: ${appCost}$ (Incluye IVA)`} fontSize={'14pt'} color={'var(--main-text-color0)'} align={'right'} />
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <form key={'formCompanyQty'}>
                <Grid container alignItems={'flex-start'}>
                  <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
                    <NumberTextField
                      id={'txtQtyOfCompanies'}
                      name={'companyQty'}
                      register={registerQty}
                      setValue={setValueQty}
                      label={'Cantidad de empresas'}
                      fullWidth={true}
                      disabled={!isInfoLic} />
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
          {
            currentPaymentMethod === "1"
              ? <Transfer
                banks={banksAdded}
                addBank={fnAddBank}
                delBank={fnDelBank}
                setPayAmount={setMaxPayAmount}
                setIsLoading={setIsLoading}
                isPersBill={isPersBill}
                appCost={appCost} />
              : currentPaymentMethod === "2"
                ? <Totalcoin registerTc={registerTc} errorsTc={errorsTc} handleSubmitTc={handleSubmitTc} fnOnSubmitTc={fnOnSubmitTc} />
                : currentPaymentMethod === "4"
                  ? <Zelle fnGetZelleCode={fnGetZelleCode} zelleCode={zelleCode} fnOnSubmitZelle={fnOnSubmitZelle} isLoading={isLoading} />
                  : ""
          }
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="right" hidden={currentPaymentMethod === "" || currentPaymentMethod === "4"}>
              <TaButton label='Registar' onClick={fnSubmitRegister} disabled={isLoading} fontSize="12pt"></TaButton>
            </Grid>
          </Grid>
          {isOpenModalConfirm && <ModalConfirmation
            onClose={fnModalConfirm}
            fnAcceptBussinessPartner={fnAcceptBussinessPartner}
            businessPartnerInfo={businessPartnerInfo} />}
        </>
      }
      loading={isLoading}
    />
  )
})

export default RegisterAccounting
