const TipoWeb = 1;
let urlTc = ''
let urlRm = ''
switch(TipoWeb){
    case 0:
        urlTc = 'http://172.16.0.57/ApiTC/'
        urlRm = 'http://172.16.0.57/apiReconversionTotal/'
    break
    case 1:
        urlTc = 'https://totalcoinqa.totalaplicaciones.com:8087/ApiTC/'
        urlRm = 'https://totalcoinqa.totalaplicaciones.com:8087/apiReconversionTotal/'
    break
    case 2:
        urlTc = 'https://totalcoin.totalaplicaciones.com:8086/ApiTC/'
        urlRm = 'https://totalcoin.totalaplicaciones.com:8086/apiReconversionTotal/'
    break
    default:
}

export {urlTc, urlRm}
 