import { memo, useState, useContext, Fragment, useMemo, useEffect, useCallback } from 'react';
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types';
import TaDialog from '../../components/dialog/TaDialog'
import { useForm } from "react-hook-form";
import TaButton from '../../components/button/TaButton';
import { SnackbarContext } from 'components/snackbar/SnackbarContext';
import { getLicense } from "services/API/license/";
import { postLogin } from "services/API/login";
import TaTypography from 'components/typography/TaTypography';
import React from 'react';
import InfoLicense from './InfoLicense';
import Totalcoin from "./Totalcoin";
import { initialValuesLic, initialValuesTc, initialInfoAliComerModel, initialIPaymentMethod } from "./RegisterModel";
import { getZelleCode, postZelle } from "services/API/zelle/"
import Zelle from "./Zelle";
import Transfer from "./Transfer";
import uuid from "react-uuid"
import { isOK } from 'helper/RestStatus';
import { postRegister } from 'services/API/register';
import TaListTextField from 'components/textfield/TaListTextField';
import ModalConfirmation from './ModalConfirmation';
import { paymentMethodsList } from "./RegisterConstants";
import { fnCalculateIvaForRetAgent } from "./RegisterFunctions";

const Register = memo(({ onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isInfoLic, setIsInfoLic] = useState(false);
  const [isDisabledField, setIsDisabledField] = useState(true);
  const { sbDispatch } = useContext(SnackbarContext);
  const [banksAdded, setBanksAdded] = useState([]);
  const [zelleCode, setZelleCode] = useState("");
  const [maxPayAmount, setMaxPayAmount] = useState(0)
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false)
  const [businessPartnerInfo, setBusinessPartnerInfo] = useState(initialInfoAliComerModel);
  const [appCost, setAppCost] = useState(70)

  const { handleSubmit: handleSubmitLic, register: registerLic, errors: errorsLic, setValue: setValueLic, watch: watchLic, getValues: getValuesLic, control } =
    useForm({ defaultValues: initialValuesLic });

  const isPersBill = watchLic("isPersBill")
  const watchIsAgentRet = watchLic('isAgentRet')

  const resetLic = () => {
    setValueLic('license', '')
    setValueLic('licenseType', '')
    setValueLic('fiscalID', '')
    setValueLic('fiscalName', '')
    setValueLic('email', '')
    setValueLic('phone', '')
    setValueLic('isPersBill', false)
    setValueLic('isAgentRet', false)
    setIsInfoLic(false)
  }

  const resetTc = () => {
    setValueTc('email', '')
    setValueTc('pass', '')
    setValueTc('balance', '')
    setValueTc('price', '')
    setValueTc('remaining', '')
    setValueTc('codAliComer', '')
  }

  const { handleSubmit: handleSubmitTc, register: registerTc, errors: errorsTc, setValue: setValueTc, watch: watchTc, getValues: getValuesTc } =
    useForm({ defaultValues: initialValuesTc });

  const { register: registerPay, setValue: setValuePay, watch: watchPay, getValues: getValuesPay } = useForm({ defaultValues: initialIPaymentMethod });

  const fnWipeRegisterForm = () => {
    resetLic()
    resetTc();
    setValuePay('paymentMethod', '')
    setBanksAdded([])
  }

  const fnOnSubmitLic = async (data) => {
    setIsLoading(true);
    try {
      const response = await getLicense(data.license)
      if (response.status === 200) {
        const { LegalEmail, LegalId, LegalName, LegalPhone, LicenseType, DataALC } = response.data
        setIsDisabledField(false);
        setValueLic("licenseType", LicenseType)
        setValueLic("fiscalID", LegalId)
        setValueLic("fiscalName", LegalName)
        setValueLic("email", LegalEmail)
        setValueLic("phone", LegalPhone)
        if (DataALC?.name != null) {
          setBusinessPartnerInfo(DataALC)
          fnModalConfirm();
        }
        setIsDisabledField(true);
        setIsInfoLic(true);
      } else {
        sbDispatch.warning(response.data?.Message ?? "Error consultando licencia, reintente nuevamente")
        resetLic()
      }
    } catch (error) {
      sbDispatch.warning("Error no controlado consultando licencia, reintente nuevamente")
      console.error('error on fnOnSubmitLic license => ', error)
    }
    setIsLoading(false);
  }

  const fnOnSubmitTc = async (data) => {
    setIsLoading(true);
    try {
      const response = await postLogin(data.email, data.pass)
      if (response.status === 200) {
        const { codUser, dif, saldoTC, valorApp } = response.data[0]
        setValueTc("codAliComer", codUser)
        setValueTc("balance", saldoTC)
        setValueTc("price", valorApp)
        setValueTc("remaining", dif)
      } else {
        resetTc();
        sbDispatch.warning(response.data?.Message ?? "Error iniciando sesion contra totalcoin, reintente nuevamente")
      }
    } catch (error) {
      sbDispatch.warning("Error no controlado iniciando sesion contra totalcoin, reintente nuevamente")
      console.error('error on fnOnSubmitTc license => ', error)
    }
    setIsLoading(false);
  }

  const fnGetZelleCode = async () => {
    setIsLoading(true);
    try {
      const response = await getZelleCode()
      if (response.status === 200) {
        const { cod } = response.data
        setZelleCode(cod)
      } else {
        setZelleCode("")
        sbDispatch.warning(response.data?.message ?? "Error obteniendo informacion de Zelle, reintente nuevamente")
      }
    } catch (error) {
      sbDispatch.warning("Error no controlado obteniendo informacion de Zelle, reintente nuevamente")
      console.error('error on fnOnSubmitTc license => ', error)
    }
    setIsLoading(false);
  }

  const fnAddBank = useCallback((data) => setBanksAdded(preVal => [...preVal, { ...data, id: uuid() }]), [])
  const fnDelBank = useCallback((id) => setBanksAdded(preVal => preVal.filter(x => x.id !== id)), [])

  const fnOnSubmitZelle = async () => {
    setIsLoading(true)
    try {
      const response = await postZelle(zelleCode, appCost)
      if (isOK(response.status)) {
        if (response.data.validated === true) {
          fnPostRegister()
        }else{
          fnPostRegister(zelleCode)
          //sbDispatch.warning('No se pudo verificar el pago, contacte a administración')
        }
      }
    } catch (error) {
      console.error('fnOnSubmitZelle =>', error)
      sbDispatch.warning('Error no controlado verificar el pago, intente nuevamente')
    }
    setIsLoading(false)
  }

  const fnPostRegister = async (zelleCode = '') => {
    setIsLoading(true)
    try {
      const response = await postRegister(getValuesLic(), getValuesTc(), getValuesPay(), banksAdded, appCost, zelleCode)
      if (isOK(response.status)) {
        fnWipeRegisterForm()
        sbDispatch.success('Se ha registrado con exito')
      }else{
        sbDispatch.api(response.status, response.data)
      }
    } catch (error) {
      console.error('fnPostRegister =>', error)
      sbDispatch.warning('Error no controlado realizando el registro, reintente nuevamente')
    }
    setIsLoading(false)
  }

  const fnSubmitRegister = async () => {
    try {
      switch (currentPaymentMethod) {
        case "1":
          if (banksAdded.reduce((val, x) => (val + x.amount), 0) !== maxPayAmount) {
            return sbDispatch.warning("Debe cancelar el monto completo")
          }
          break
        case "2":
          if (getValuesTc("codAliComer") === "") {
            return sbDispatch.warning("Debe iniciar sesión para registrar la compra")
          }
          break;
        default:
      }
      return fnPostRegister()
    } catch (error) {
      console.error('fnSubmitRegister => ', error)
      sbDispatch.warning("Error no controlado realizando el registro de la compra, intente nuevamente")
    }
  }

  const fnModalConfirm = () => setIsOpenModalConfirm(!isOpenModalConfirm)

  const fnAcceptBussinessPartner = (id) => {
    switch (id) {
      case 'btnContact':
        resetLic()
        break;
      default:
        break
    }
    setBusinessPartnerInfo(initialInfoAliComerModel)
    fnModalConfirm()
  }

  const currentPaymentMethod = useMemo(() => {
    switch (watchPay("paymentMethod")) {
      case "1":
      case "3":
      case "4":
        resetTc();
        break
      default:
        break
    }
    return watchPay("paymentMethod");
  }, [watchPay("paymentMethod")])

  useEffect(() => {
    if (isInfoLic === true && watchLic("license").length !== 19) {
      resetLic();
      setValuePay('paymentMethod', '');
    }
  }, [watchLic("license"), isInfoLic])
  useEffect(() => getValuesTc("codAliComer") !== "" && resetTc(), [watchTc("email"), watchTc("pass")])
  useEffect(() => setValueLic('isAgentRet', false), [isPersBill])
  useEffect(() => currentPaymentMethod !== "" && setAppCost(fnCalculateIvaForRetAgent(watchIsAgentRet)), [watchIsAgentRet, currentPaymentMethod])

  return (
    <TaDialog
      isOpen={true}
      maxWidth='lg'
      onClose={onClose}
      title={'Comprar Licencia'}
      content={
        <Fragment>
          <InfoLicense registerLic={registerLic} errorsLic={errorsLic} handleSubmitLic={handleSubmitLic} fnOnSubmitLic={fnOnSubmitLic}
            isDisabledField={isDisabledField} isInfoLic={isInfoLic} isPersBill={isPersBill} control={control} />
          <form key="payment" onKeyPress={(e) => (e.key === "Enter" && e.preventDefault())}>
            <Grid container spacing={2} alignItems="center" alignContent="center">
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TaTypography text="Forma de pago" fontSize="14pt" color="var(--main-text-color0)" />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                <TaListTextField
                  name={'paymentMethod'}
                  label={'Método de pago'}
                  register={registerPay}
                  required={true}
                  setValue={setValuePay}
                  errors={errorsLic}
                  options={paymentMethodsList}
                  fullWidth={true}
                  disabled={!isInfoLic}/>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={8} xl={8} hidden={currentPaymentMethod === '2' || currentPaymentMethod === ''}>
                <TaTypography text={`Monto a pagar: ${appCost}$ (Incluye IVA)`} fontSize="14pt" color="var(--main-text-color0)" align="right" />
              </Grid>
            </Grid>
          </form>
          {
            currentPaymentMethod === "1"
              ? <Transfer
                banks={banksAdded}
                addBank={fnAddBank}
                delBank={fnDelBank}
                setPayAmount={setMaxPayAmount}
                setIsLoading={setIsLoading}
                isPersBill={isPersBill}
                appCost={appCost} />
              : currentPaymentMethod === "2"
                ? <Totalcoin registerTc={registerTc} errorsTc={errorsTc} handleSubmitTc={handleSubmitTc} fnOnSubmitTc={fnOnSubmitTc} />
                : currentPaymentMethod === "4"
                  ? <Zelle fnGetZelleCode={fnGetZelleCode} zelleCode={zelleCode} fnOnSubmitZelle={fnOnSubmitZelle} isLoading={isLoading} />
                  : ""
          }
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="right" hidden={currentPaymentMethod === "" || currentPaymentMethod === "4"}>
              <TaButton label='Registar' onClick={fnSubmitRegister} disabled={isLoading} fontSize="12pt"></TaButton>
            </Grid>
          </Grid>
          {isOpenModalConfirm && <ModalConfirmation
            isOpen={isOpenModalConfirm}
            onClose={fnModalConfirm}
            fnAcceptBussinessPartner={fnAcceptBussinessPartner}
            businessPartnerInfo={businessPartnerInfo} />}
        </Fragment>
      }
      loading={isLoading} />
  );
});

Register.propTypes = {
  onClose: PropTypes.func,
};

export default Register;